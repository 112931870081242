exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-contact-us-components-contact-process-index-js": () => import("./../../../src/pages/contact-us/components/contactProcess/index.js" /* webpackChunkName: "component---src-pages-contact-us-components-contact-process-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-expertise-index-js": () => import("./../../../src/pages/expertise/index.js" /* webpackChunkName: "component---src-pages-expertise-index-js" */),
  "component---src-pages-expertise-service-links-index-js": () => import("./../../../src/pages/expertise/serviceLinks/index.js" /* webpackChunkName: "component---src-pages-expertise-service-links-index-js" */),
  "component---src-pages-get-started-index-js": () => import("./../../../src/pages/get-started/index.js" /* webpackChunkName: "component---src-pages-get-started-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-root-page-components-build-for-all-index-js": () => import("./../../../src/pages/rootPage/components/buildForAll/index.js" /* webpackChunkName: "component---src-pages-root-page-components-build-for-all-index-js" */),
  "component---src-pages-root-page-components-dna-to-success-index-js": () => import("./../../../src/pages/rootPage/components/dnaToSuccess/index.js" /* webpackChunkName: "component---src-pages-root-page-components-dna-to-success-index-js" */),
  "component---src-pages-root-page-components-home-banner-index-js": () => import("./../../../src/pages/rootPage/components/homeBanner/index.js" /* webpackChunkName: "component---src-pages-root-page-components-home-banner-index-js" */),
  "component---src-pages-root-page-components-how-we-do-index-js": () => import("./../../../src/pages/rootPage/components/howWeDo/index.js" /* webpackChunkName: "component---src-pages-root-page-components-how-we-do-index-js" */),
  "component---src-pages-root-page-components-how-we-do-process-steps-js": () => import("./../../../src/pages/rootPage/components/howWeDo/processSteps.js" /* webpackChunkName: "component---src-pages-root-page-components-how-we-do-process-steps-js" */),
  "component---src-pages-root-page-components-our-services-circle-services-js": () => import("./../../../src/pages/rootPage/components/ourServices/circleServices.js" /* webpackChunkName: "component---src-pages-root-page-components-our-services-circle-services-js" */),
  "component---src-pages-root-page-components-our-services-index-js": () => import("./../../../src/pages/rootPage/components/ourServices/index.js" /* webpackChunkName: "component---src-pages-root-page-components-our-services-index-js" */),
  "component---src-pages-root-page-index-js": () => import("./../../../src/pages/rootPage/index.js" /* webpackChunkName: "component---src-pages-root-page-index-js" */),
  "component---src-templates-service-service-template-js": () => import("./../../../src/templates/service/service-template.js" /* webpackChunkName: "component---src-templates-service-service-template-js" */)
}

