/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
export const onRouteUpdate = ({ location }) => {
    if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
            event: "pageview",
            page: location.pathname,
        });
    }
};
